export const GameFormConfig = [
  {
    gameName: 'amb-pgslot',
    gameId: 'd24d0446-65a6-4994-88e1-908382d38140',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_AGENT_NAME', type: 'text' },
      { key: 'GAME_SECRET', type: 'text' },
    ],
  },
  {
    gameName: 'pokeramb',
    gameId: 'ab46d250-37cc-440a-a16b-fa679b041821',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_AGENT_ID', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
    ],
  },
  {
    gameName: 'cq9',
    gameId: 'ebcd8ac2-9e43-4783-a099-f9479290d429',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_AGENT_ID', type: 'text' },
      { key: 'GAME_API_TOKEN', type: 'text' },
      { key: 'GAME_API_W_TOKEN', type: 'text' },
    ],
  },
  {
    gameName: 'dg',
    gameId: '07e10188-f350-4b59-ba84-6326dfa32784',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_AGENT_NAME', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
      { key: 'GAME_BET_LIMIT', type: 'bet_limit' },
    ],
  },
  {
    gameName: 'evoplay',
    gameId: '2908f8f2-68fa-435f-b009-8d5b968ef375',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_PROJECT_ID', type: 'text' },
      { key: 'GAME_SECRET_KEY', type: 'text' },
    ],
  },
  {
    gameName: 'hotgraph',
    gameId: 'ae053801-2dd7-4193-be1d-69dae1195c48',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_AGENT_ID', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
    ],
  },
  {
    gameName: 'joker',
    gameId: '3fd2fbbc-6b07-40a3-9dd5-cdb742a2aa92',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_APP_ID', type: 'text' },
      { key: 'GAME_SECRET', type: 'text' },
    ],
  },
  {
    gameName: 'pg',
    gameId: 'a3aa9e4f-ceb7-4553-8273-b3e8dddfd106',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_OPERATOR_TOKEN', type: 'text' },
      { key: 'GAME_PUBLIC_DOMAIN', type: 'text' },
      { key: 'GAME_SECRET', type: 'text' },
    ],
  },
  {
    gameName: 'pragmatic',
    gameId: '6aa2a4f7-4ffa-4c84-b391-49936b19167b',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_SERVER_DOMAIN', type: 'text' },
      { key: 'GAME_SECRET_KEY', type: 'text' },
      { key: 'GAME_SECURE_LOGIN', type: 'text' },
    ],
  },
  {
    gameName: 'rich88',
    gameId: '7bbefd12-2b8f-4563-aff2-6bb17e388d44',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_PF_ID', type: 'text' },
      { key: 'GAME_PRIVATE_KEY', type: 'text' },
      { key: 'GAME_SESSION_ID', type: 'text' },
    ],
  },
  {
    gameName: 'sa',
    gameId: '082f5256-28a1-4f0c-9855-0b02c78e5416',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_APP_ENCRPT_KEY', type: 'text' },
      { key: 'GAME_ENCRYPT_KEY', type: 'text' },
      { key: 'GAME_LOBBY_CODE', type: 'text' },
      { key: 'GAME_MD5_KEY', type: 'text' },
      { key: 'GAME_SECRET_KEY', type: 'text' },
      { key: 'GAME_BET_LIMIT', type: 'bet_limit' },
    ],
  },
  {
    gameName: 'sbo',
    gameId: 'e76b377e-4636-45a5-80c2-c34fb7a31dd7',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_AGENT_NAME', type: 'text' },
      { key: 'GAME_COMPANY_KEY', type: 'text' },
      { key: 'GAME_SERVER_ID', type: 'text' },
      { key: 'GAME_MIN_BET', type: 'text' },
      { key: 'GAME_MAX_BET', type: 'text' },
      { key: 'GAME_MAX_PER_MATCH', type: 'text' },
      { key: 'GAME_CASINO_TABLE_LIMIT', type: 'text' },
    ],
  },
  {
    gameName: 'sexygame',
    gameId: '76701bda-6110-4fe1-86d9-70e9138f302a',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_AGENT_ID', type: 'text' },
      { key: 'GAME_BET_LIMIT', type: 'bet_limit' },
      { key: 'GAME_CERT', type: 'text' },
      { key: 'GAME_PREFIX', type: 'text' },
    ],
  },
  {
    gameName: 'simpleplay',
    gameId: '8a106ba5-dfbf-42b4-adaf-38f648121a53',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_ENCRYPT_KEY', type: 'text' },
      { key: 'GAME_LOBBY_CODE', type: 'text' },
      { key: 'GAME_MD5_KEY', type: 'text' },
      { key: 'GAME_SECRET_KEY', type: 'text' },
    ],
  },
  {
    gameName: 'wm',
    gameId: '439b0cbf-5b15-466f-b71c-319fef901d58',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_SIGNATURE', type: 'text' },
      { key: 'GAME_VENDOR_ID', type: 'text' },
      { key: 'GAME_BET_LIMIT', type: 'bet_limit' },
    ],
  },
  {
    gameName: 'yl',
    gameId: '63b80ceb-e23e-4fdc-9a2e-3835c9fb3889',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_AGENT_ID', type: 'text' },
      { key: 'GAME_CERT', type: 'text' },
      { key: 'GAME_CURRENCY', type: 'text' },
      { key: 'GAME_WEBSITE', type: 'text' },
    ],
  },
  {
    gameName: 'prettygaming',
    gameId: '8c3bd24d-886c-45a7-b8eb-b90489dcdd93',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
      { key: 'GAME_BET_LIMIT', type: 'bet_limit' },
      { key: 'GAME_AGENT_USERNAME', type: 'text' },
    ],
  },
  {
    gameName: 'jili',
    gameId: '8fb0dfe4-5baa-4c2a-8862-4a468484657b',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_AGENT_ID', type: 'text' },
      { key: 'GAME_AGENT_KEY', type: 'text' },
    ],
  },
  {
    gameName: 'kingmaker',
    gameId: 'f5b6ed50-2baf-47fb-ad76-5f5426d3bf79',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_CLIENT_ID', type: 'text' },
      { key: 'GAME_CLIENT_SECRET', type: 'text' },
      { key: 'GAME_LOBBY_URL', type: 'text' },
    ],
  },
  {
    gameName: 'evolution',
    gameId: '9e770e44-b84d-4700-a051-39c2c8ae6bef',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_MERCHANT_ID', type: 'text' },
      { key: 'GAME_CASINO_KEY', type: 'text' },
      { key: 'GAME_AUTH_TOKEN', type: 'text' },
      { key: 'GAME_BRAND_ID', type: 'text' },
    ],
  },
  {
    gameName: 'relax',
    gameId: '160a0e64-6bcb-487b-8a72-06bfd6e25756',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_PARTNER_ID', type: 'text' },
      { key: 'GAME_PARTNER_NAME', type: 'text' },
      { key: 'GAME_USERNAME', type: 'text' },
      { key: 'GAME_PASSWORD', type: 'text' },
    ],
  },
  {
    gameName: 'xg',
    gameId: 'dfe71e9a-051f-4d86-a09a-d1ea3a8e5bf6',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_AGENT_ID', type: 'text' },
      { key: 'GAME_AGENT_KEY', type: 'text' },
      { key: 'GAME_BET_LIMIT', type: 'bet_limit' },
    ],
  },
  {
    gameName: 'bg',
    gameId: '3d142e92-ac1b-41aa-8de6-14bd52e02cf1',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
      { key: 'GAME_SN_CODE', type: 'text' },
    ],
  },
  {
    gameName: 'ag',
    gameId: '5316327b-d8cf-43f7-9534-746fdab5110a',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_API_URL_GCI', type: 'text' },
      { key: 'GAME_API_URL_SESSION', type: 'text' },
      { key: 'GAME_AGENT', type: 'text' },
      { key: 'GAME_MD5_KEY', type: 'text' },
      { key: 'GAME_DES_KEY', type: 'text' },
      { key: 'GAME_BET_LIMIT', type: 'bet_limit' },
    ],
  },
  {
    gameName: 'allbet',
    gameId: 'f5d84c95-9e37-4f42-a1a8-ed25b4cc9e0d',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_OPERATOR_ID', type: 'text' },
      { key: 'GAME_SUFFIX', type: 'text' },
      { key: 'GAME_ALLBET_KEY', type: 'text' },
      { key: 'GAME_PARTNER_KEY', type: 'text' },
      { key: 'GAME_AGENT', type: 'text' },
      { key: 'GAME_BET_LIMIT', type: 'bet_limit' },
    ],
  },
  {
    gameName: 'mpoker',
    gameId: 'a1280278-4fa0-44f2-89d6-f3ca6ee97694',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_MD5_KEY', type: 'text' },
      { key: 'GAME_AES_KEY', type: 'text' },
      { key: 'GAME_AGENT_ID', type: 'text' },
    ],
  },
  {
    gameName: 'cherry',
    gameId: '642d521e-0668-42f8-b6cc-f6cd6659998d',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
      { key: 'GAME_MD5_KEY', type: 'text' },
      { key: 'GAME_DES_KEY', type: 'text' },
      { key: 'GAME_LOBBY_CODE', type: 'text' },
      { key: 'GAME_BET_LIMIT', type: 'bet_limit' },
    ],
  },
  {
    gameName: 'bti',
    gameId: '294594f4-0302-46d8-a75d-09d946f7681f',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_API_JS_URL', type: 'text' },
    ],
  },
  {
    gameName: 'spinix',
    gameId: 'ca12aa3e-afe2-4ff2-94f7-25af1ed7c05a',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_PLATFORM_ID', type: 'text' },
      { key: 'GAME_SECRET_KEY', type: 'text' },
      { key: 'GAME_SIGNATURE_KEY', type: 'text' },
    ],
  },
  {
    gameName: 'pgslot',
    gameId: 'd309e637-7064-49f6-81c8-0eb910ce5104',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
    ],
  },
  {
    gameName: 'naga',
    gameId: '3b4ec21a-ccd3-49fa-8540-1c65a1cfd7d5',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
      { key: 'GAME_GROUP_CODE', type: 'text' },
      { key: 'GAME_BRAND_CODE', type: 'text' },
      { key: 'GAME_PUBLIC_KEY', type: 'text' },

    ],
  },
  {
    gameName: 'micro',
    gameId: '31f30d2e-18a7-4da5-b015-1ac8cc85f9f0',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_TOKEN_URL', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
      { key: 'GAME_AGENT_CODE', type: 'text' },
    ],
  },
  {
    gameName: 'gclub',
    gameId: '8623a742-aab5-4f12-a7d2-9f5ce8b8df45',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_CLIENT_ID', type: 'text' },
      { key: 'GAME_CLIENT_SECRET', type: 'text' },
      { key: 'GAME_DES_KEY', type: 'text' },
      { key: 'GAME_DES_IV', type: 'text' },
      { key: 'GAME_SYSTEM_CODE', type: 'text' },
      { key: 'GAME_WEB_ID', type: 'text' },
      { key: 'GAME_BET_LIMIT', type: 'bet_limit' },
    ],
  },
  {
    gameName: 'gclubslot',
    gameId: '89c1c701-4f11-45c3-9a5f-57876ba65d29',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_CLIENT_ID', type: 'text' },
      { key: 'GAME_CLIENT_SECRET', type: 'text' },
      { key: 'GAME_DES_KEY', type: 'text' },
      { key: 'GAME_DES_IV', type: 'text' },
      { key: 'GAME_SYSTEM_CODE', type: 'text' },
      { key: 'GAME_WEB_ID', type: 'text' },
    ],
  },
  {
    gameName: 'qtech',
    gameId: 'f4a41247-affe-4dc3-89f0-c67aad6ad9a5',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_USERNAME', type: 'text' },
      { key: 'GAME_PASSWORD', type: 'text' },
      { key: 'GAME_PASS_KEY', type: 'text' },
    ],
  },
  {
    gameName: 'pgmga',
    gameId: 'f4a41247-affe-4dc3-89f0-c67aad6ad9a5',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_OPERATOR_TOKEN', type: 'text' },
      { key: 'GAME_SECRET', type: 'text' },
    ],
  },
  {
    gameName: 'pgpt',
    gameId: 'f4a41247-affe-4dc3-89f0-c67aad6ad9a5',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_OPERATOR_TOKEN', type: 'text' },
      { key: 'GAME_SECRET', type: 'text' },
    ],
  },
  {
    gameName: 'dragongaming',
    gameId: 'f4a41247-affe-4dc3-89f0-c67aad6ad9a5',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
    ],
  },
  {
    gameName: 'amigo',
    gameId: 'f4a41247-affe-4dc3-89f0-c67aad6ad9a5',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
      { key: 'GAME_STYLE_NAME', type: 'text' },
      { key: 'GAME_CURRENCY', type: 'text' },
    ],
  },
  {
    gameName: 'gpgsuperapi',
    gameId: 'f4a41247-affe-4dc3-89f0-c67aad6ad9a5',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
      { key: 'GAME_OPERATOR_TOKEN', type: 'text' },
      { key: 'GAME_ENCRYPT_KEY', type: 'text' },
      { key: 'GAME_USERNAME', type: 'text' },
    ],
  },
  {
    gameName: 'via',
    gameId: 'f4a41247-affe-4dc3-89f0-c67aad6ad9a5',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_API_KEY', type: 'text' },
      { key: 'GAME_API_VENDER_ID', type: 'text' },
      { key: 'GAME_ENCRYPT_KEY', type: 'text' },
      { key: 'GAME_USERNAME', type: 'text' },
    ],
  },
  {
    gameName: 'playstar',
    gameId: 'f4a41247-affe-4dc3-89f0-c67aad6ad9a5',
    input: [
      { key: 'GAME_API_URL', type: 'text' },
      { key: 'GAME_HOST_ID', type: 'text' },
    ],
  },
]