<template>
  <div>
    <b-form-checkbox
      v-if="!requireGameKey"
      v-model="hasGameKey"
      switch
      @input="(v) => onInputChange('hasGameKey', v)"
      >ต้องการกรอกข้อมูล</b-form-checkbox
    >

    <b-row v-if="GameFormConfig && hasGameKey">
      <b-col
        v-for="input in GameFormConfig.input"
        :key="input.key"
        lg="4"
        sm="12"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :name="`${gameName}-${input.key}`"
          rules=""
        >
          <b-form-group label-cols="12" :label="input.key">
            <b-form-input
              v-if="input.type === 'text'"
              :state="errors[0] ? false : null"
              :value="value && value[input.key] ? value[input.key] : 0"
              required
              @input="(v) => onInputChange(input.key, v)"
            />
            <multiselect
              v-if="input.type === 'bet_limit'"
              :value="value && value[input.key]"
              placeholder=""
              :multiple="true"
              :options="BetLimitConfig[gameName].options"
              :max="BetLimitConfig[gameName].max"
              label="text"
              track-by="value"
              @input="(v) => onBetLimitChange(input.key, v)"
            >
              <template slot="option" slot-scope="props">
                <div class="d-flex align-items-center" style="height: 25px">
                  <span class="mx-2">{{ props.option.text }}</span>
                </div>
              </template>
            </multiselect>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { GameFormConfig } from './game-form'
import { BetLimit } from '@src/views/main/players/bet-limit'

export default {
  name: 'KeyForm',
  props: {
    gameName: {
      type: String,
      default: undefined,
    },
    gameId: {
      type: String,
      default: undefined,
    },
    value: {
      type: Object,
      default: undefined,
    },
    requireGameKey: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasGameKey: false,
    }
  },
  computed: {
    GameFormConfig() {
      return (
        GameFormConfig.find(
          (config) =>
            config.gameName.trim().toLowerCase() ===
              this.gameName?.trim()?.toLowerCase() ||
            config.gameId === this.gameId
        ) || {}
      )
    },
    BetLimitConfig() {
      return BetLimit
    },
  },
  mounted() {
    this.hasGameKey = this.requireGameKey ? true : this.value.hasGameKey
  },
  methods: {
    onInputChange(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    onBetLimitChange(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
  },
}
</script>